import React, { useState, useCallback, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import ArrowDown from "./ArrowDown";
import { useTranslation } from "react-i18next";
import useHomeSlider from "../hooks/zustand/useHomeSlider";

const autoplayOptions = {
  stopOnInteraction: false,
  // stopOnMouseEnter: true,
  // playOnInit: false,
  delay: 10000, // 3 second
};

const Carousel = () => {
  const { t, i18n } = useTranslation();
  const [userLanguage, setUserLanguage] = useState(i18n.language);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isHomeSliderStop, setIsHomeSliderStop] = useState(false);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      containScroll: "trimSnaps",
      loop: true,
    },
    [Autoplay(autoplayOptions)]
  );

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.plugins().autoplay?.reset();
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);
  const homeSliders = useHomeSlider();

  useEffect(() => {
    const selectedLanguage = i18n.language || userLanguage;
    setUserLanguage(selectedLanguage);
  }, [i18n.language]);

  const getData = async () => {
    await homeSliders.getHomeSlider();
    setIsHomeSliderStop(true);
  };

  useEffect(() => {
    if (!isHomeSliderStop) {
      getData();
    }
  }, []);

  const title = "title_" + i18n.language;
  const desc = "desc_" + i18n.language;
  const alt_img = "alt_img_" + i18n.language;
  return (
    <div className="relative">
      <div ref={emblaRef} className="overflow-hidden max-h-screen">
        <div className="flex">
          <div
            key={0}
            className="flex w-full flex-shrink-0 h-[90vh] bg-bl"
            style={{
              backgroundImage: "url('/images/home_bgImg.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
          <div className="w-full flex flex-col lg:flex-row gap-4  ">
            <div className="flex flex-col justify-center items-center sm:items-start py-10">
              <div className="w-1/4">
                <img src="images/logo.png"></img>
              </div>
            </div>
          </div>
            {/* <div className="w-full flex flex-col lg:flex-row gap-4 items-center justify-center px-5">
              <div className="flex flex-col gap-4 lg:w-[35rem]">
                <h1 className="text-4xl lg:text-6xl text-white font-bold">
                  {item[title]}
                </h1>
                <p className="text-2xl lg:text-4xl text-white">
                  {item[desc]}
                </p>
              </div>
              <div className="w-72 lg:w-96">
                <img src={item.src_img} alt={item[alt_img]} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="absolute top-1/2 w-full hidden lg:flex justify-center gap-4">
        <div className="w-full flex justify-between mx-3">
          <button onClick={() => emblaApi?.scrollPrev()}>
            <span className="sr-only">Previous</span>
            <ArrowDown className="w-5 rotate-90 text-white" />
          </button>
          <button onClick={() => emblaApi?.scrollNext()}>
            <span className="sr-only">Next</span>
            <ArrowDown className="w-5 -rotate-90 text-white" />
          </button>
        </div>
      </div> */}
      {/* <div className="absolute bottom-5 w-full flex justify-center gap-4">
        {homeSliders.data.map((_, index) => {
          return (
            <button
              key={index}
              className={`h-2 lg:h-3 w-2 lg:w-3 rounded-full ${
                index === selectedIndex ? "bg-blue-400" : "bg-gray-100"
              }`}
              onClick={() => {
                emblaApi?.scrollTo(index);
                emblaApi?.plugins().autoplay?.reset();
              }}
            >
              <span className="sr-only">{`Select slide ${index + 1}`}</span>
            </button>
          );
        })}
      </div> */}
    </div>
  );
};

export default Carousel;
