import { create } from 'zustand';
import { Api } from '../../lib/api/axios';

function personalInformationInitialState() {
  const initialState = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    bankHolder: '',
    bankAccount: '',
    bankName: '',
    date: '',
    __v: '',
    role: '',
  };
  return initialState;
}

const usePersonalInformation = create((set, get) => ({
  user: personalInformationInitialState(),
  todayTrade: [],
  change: (payload) =>
    set((state) => ({ user: { ...state.user, ...payload } })),
  getData: async () => {
    try {
      const res = await Api.get('/auth/get-me');
      set(() => ({ user: { ...res.data.user } }));
      return res.data.user;
    } catch (error) {
      return error.response?.data;
    }
  },
  updateData: async (payload) => {
    try {
      const { status } = await Api.patch('/auth/update-user-data', payload);
      if (status === 200 && !payload.password) {
        set((state) => ({ user: { ...state.user, ...payload } }));
      }
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
  checkCurrentPassword: async (payload) => {
    try {
      const res = await Api.post('/auth/check-password', payload);
      return res.data?.message;
    } catch (error) {
      return error;
    }
  },
  reset: () => {
    set(() => ({ user: personalInformationInitialState() }));
  },
  getTodayTrade: async (signal) => {
    try {
      const res = await Api.get(
        `/cms/investment/today-trade/${get().user._id}`,
        { signal }
      );
      set(() => ({ todayTrade: res.data }));
      return res.status;
    } catch (error) {
      return error;
    }
  },
  updateUserSuspendedAttempts: async (payload) => {
    try{
      const res = await Api.post(
        `cms/list-member/update-suspended-attempts/${get().user._id}`,
        {payload}
      );
      return res.message;
    }
    catch (error) {
      return error;
    }
  },
}));

export default usePersonalInformation;
