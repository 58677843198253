export default function toRinggit(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes('.')) {
    const parts = formattedAmount.split('.');
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    formattedAmount = integerPart + '.' + parts[1];
  }

  return   formattedAmount + ' USD';
}


export function currency(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-US');
  return formattedAmount;
}

export function convertToNumber (amount = 0) {
  let formattedAmount = Number(amount.replace(/,/g,'').replace('USD', ''));
  return formattedAmount;
}
