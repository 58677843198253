import React from 'react';
import { cva } from 'class-variance-authority';
import { clsxm } from '../../../lib/utils';

const variants = cva('card w-full h-full p-3 lg:p-5', {
  variants: {
    backgroundColor: {
      primary: ['bg-card-primary'],
      secondary: ['bg-card-secondary'],
      white: ['bg-white'],
    },
    radius: {
      low: 'rounded-md',
      medium: 'rounded-xl',
      full: 'rounded-full',
    },
  },
  defaultVariants: {
    backgroundColor: 'primary',
    radius: 'medium',
  },
});

export default function Card({ className, backgroundColor, radius, ...props }) {
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <div
      className={clsxm(variants({ backgroundColor, radius, className }))}
      {...props}
    />
  );
}
