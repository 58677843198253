import { Route } from 'react-router-dom';
import { WithGuard } from '../middleware/route.guard';
import { lazy } from 'react';
import Dashboard from '../pages/Dashboard/Dashboard';
import ProfitLossHistoryProfile from '../pages/Dashboard/Coin/detail';

// LAZY LOADING COMPONENT
const Withdrawal = lazy(() => import('../pages/Dashboard/Withdrawal'));
const WithdrawalHistory = lazy(() =>
  import('../pages/Dashboard/WithdrawalHistory')
);
const ProfitLossHistory = lazy(() =>
  import('../pages/Dashboard/ProfitLossHistory')
);
const DepositHistory = lazy(() => import('../pages/Dashboard/DepositHistory'));
const Email = lazy(() => import('../pages/Dashboard/Email'));
const Password = lazy(() => import('../pages/Dashboard/Password'));
const BankInformation = lazy(() =>
  import('../pages/Dashboard/BankInformation')
);

const Bitcoin = lazy(() => import('../pages/Dashboard/Coin/Bitcoin'));
const Ethereum = lazy(() => import('../pages/Dashboard/Coin/Ethereum'));
const Polkadot = lazy(() => import('../pages/Dashboard/Coin/Polkadot'));
const Houbi = lazy(() => import('../pages/Dashboard/Coin/Houbi'));
const Ripple = lazy(() => import('../pages/Dashboard/Coin/Ripple'));

// MUST AUTH || USER DASHBOARD
const DashboardGuard = WithGuard(Dashboard);
const WithdrawalGuard = WithGuard(Withdrawal);
const WithdrawalHistoryGuard = WithGuard(WithdrawalHistory);
const ProfitLossHistoryGuard = WithGuard(ProfitLossHistory);
const DepositHistoryGuard = WithGuard(DepositHistory);
const EmailGuard = WithGuard(Email);
const PasswordGuard = WithGuard(Password);
const BankInformationGuard = WithGuard(BankInformation);
const BitcoinGuard = WithGuard(Bitcoin);
const EthereumGuard = WithGuard(Ethereum);
const PolkadotGuard = WithGuard(Polkadot);
const HoubiGuard = WithGuard(Houbi);
const RippleGuard = WithGuard(Ripple);
const DetailProfile = WithGuard(ProfitLossHistoryProfile);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route index element={<DashboardGuard />} />,
  <Route path='withdraw' element={<WithdrawalGuard />} />,
  <Route path='withdraw-history' element={<WithdrawalHistoryGuard />} />,
  <Route path='profit-loss-history' element={<ProfitLossHistoryGuard />} />,
  <Route path='deposit-history' element={<DepositHistoryGuard />} />,
  <Route path='email' element={<EmailGuard />} />,
  <Route path='password' element={<PasswordGuard />} />,
  <Route path='bank-information' element={<BankInformationGuard />} />,
  <Route path='bitcoin' element={<BitcoinGuard />} />,
  <Route path='ethereum' element={<EthereumGuard />} />,
  <Route path='polkadot' element={<PolkadotGuard />} />,
  <Route path='houbitoken' element={<HoubiGuard />} />,
  <Route path='ripple' element={<RippleGuard />} />,
  <Route path='profile-detail/:id' element={<DetailProfile />} />,
];
