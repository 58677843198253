import React, { useRef, useEffect, useState } from 'react';
import Card from '../../components/dashboard/card/Card';
import CardHeader from '../../components/dashboard/card/Card.header';
import Paragraph from '../../components/dashboard/typography/Paragraph';
import BadgeActive from '../../components/dashboard/badge/badge.active';
import BadgeSuspend from '../../components/dashboard/badge/badge.suspend';
import Heading from '../../components/dashboard/typography/Heading';
import Button from '../../components/dashboard/button/Button';
import { Link } from 'react-router-dom';
import usePersonalInformation from '../../hooks/zustand/usePersonalInformation';
import toRinggit from '../../lib/currencyFormatter';
import TradingViewWidget from './Coin/TradingView';
import TodayTrade from '../../components/dashboard/card/Card.today-trade';
import Spinner from '../../components/dashboard/fallback/Spinner';
import ModalDepositUser from './Modal/Modal.deposit';
import useLocalStorage from '../../hooks/useLocalStorage';

export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const [open, setOpen] = useState(false);
  const [attempt, setAttempt] = useLocalStorage('withdrawalAttempt', 0);
  const [status, setStatus] = useState(userInfo?.user.status || 'active');

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user._id]);

  useEffect(() => {
    if (userInfo?.user.status === 'active') {
      //setAttempt(0);
      const attemptPayload = {
        suspendedAttempts: 0
      };
      const res = userInfo.updateUserSuspendedAttempts(attemptPayload);
    }

    if (userInfo?.user.status === 'suspend' && userInfo?.user.suspendedAttempts >= 3) {
      setStatus('suspend');
    } else {
      setStatus(
        userInfo?.user.status !== 'suspend' ? userInfo?.user.status : 'active'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user.status]);

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);

  return (
    <div>
      <Paragraph
        size='large'
        color='white'
        className='font-semibold flex items-center space-x-2'
      >
      
        Dashboard - {status}
      </Paragraph>
      <div className='grid grid-cols-1 md:grid-cols-1  h-full lg:h-card-height gap-5 py-4 lg:py-0'>
        <Card className='flex justify-between flex-col '>
          {/* TOP CARD */}
          <CardHeader
            leftTitle='My Wallet'
            rightTitle={userInfo.user.firstName + ' ' + userInfo.user.lastName}
            rightTitleIcon={
              (status === 'active') ? (
                <BadgeActive />
              ) : status === 'suspend' ? (
                <BadgeSuspend />
              ) : (
                <Spinner />
              )
            }
            status={status}
          />
          {/* MIDDLE CARD */}
          <div className='w-full h-max '>
            <div className='w-full grid grid-cols-2'>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  Wallet
                </Paragraph>
                <Heading color='white' className='font-semibold' size='h3'>
                  {
                    // userInfo?.user.wallet ?
                    //   toRinggit(
                    //     userInfo?.user.wallet ?? 0
                    //   )
                    //   :
                    (status !== 'missinginfo') ?
                      toRinggit(
                        userInfo?.user.deposit + (userInfo?.user?.profitLoss ?? 0) - (userInfo?.user?.withdrawal ?? 0) - (userInfo?.user?.investmentCreated)
                      ) : "N/A"
                  }
                </Heading>
              </div>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  Staking Wallet
                </Paragraph>
                <Heading color='white' className='font-semibold' size='h3'>
                  {
                    // userInfo?.user.wallet ?
                    //   toRinggit(
                    //     userInfo?.user.wallet ?? 0
                    //   )
                    //   :
                    (status !== 'missinginfo') ?
                      toRinggit(
                        userInfo?.user.investmentCreated
                      ) : "N/A"
                  }
                </Heading>
              </div>
            </div>
            
            <div className='w-full grid grid-cols-2'>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  Deposit
                </Paragraph>
                <Heading color='white' className='font-semibold' size='h4'>
                  {(status !== 'missinginfo') ?
                    toRinggit(userInfo?.user.deposit ?? 0) : "N/A"}
                </Heading>
              </div>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  {'Profit/(Loss)'}
                </Paragraph>
                <Heading color='white' className='font-semibold' size='h4'>
                  {(status !== 'missinginfo') ? toRinggit(userInfo?.user?.profitLoss ?? 0) : 'N/A'}
                </Heading>
              </div>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  {'Total Withdrawal'}
                </Paragraph>
                <Heading color='white' className='font-semibold' size='h4'>
                  {(status !== 'missinginfo') ? toRinggit(userInfo?.user?.withdrawal ?? 0) : 'N/A'}
                </Heading>
              </div>
            </div>
            {/* <div className='w-full bg-secondary px-3 py-5 mt-3 rounded-lg'>
              <Button
                radius='full'
                className='py-2 text-white text-sm font-semibold'
              >
                {'Profit/(Loss)'}
              </Button>
            </div> */}
          </div>
          {/* BOTTOM CARD */}
          {/* <div className='w-full h-max grid grid-cols-1 lg:grid-cols-2 gap-5 py-1 px-3'>
            <Button
              radius='full'
              className='py-3 text-white text-base font-semibold'
              backgroundColor='secondary'
              onClick={() => setOpen(true)}
            >
              Deposit
            </Button>
            <Link to='/dashboard/withdraw'>
              <Button
                radius='full'
                className='py-3 text-white text-base font-semibold'
                backgroundColor='secondary'
              >
                Withdrawal
              </Button>
            </Link>
          </div> */}
        </Card>
        <Card className="hidden" backgroundColor='primary'>
          {/* TOP CARD */}
          <CardHeader leftTitle='Staking' />
          {(status !== 'missinginfo') ?
            userInfo?.todayTrade?.length > 0 && userInfo?.todayTrade?.map((a, b) => {
              return (
                <Link key={b} to={`/dashboard/profile-detail/${a._id}`}>
                  <TodayTrade
                    member={userInfo?.user?.phoneNumber}
                    logo={a.product.coinId}
                    profile={`${a.product.coinId} ${a.profileDesc}`}
                    // profitLoss={a.profitLossAmount + a.investAmount}
                    investAmount={a.investAmount}
                    profitLoss={a.profitLossAmount}
                    changingTime={a.profitChangingTime}
                    percentage={(
                      (a.profitLossAmount / a.investAmount) *
                      100
                    ).toFixed(1)}
                    isProfit={a.isProfit}
                    isPlay={a.isPlay}
                    playDate={a.playDate}
                    finishPlayDate={a.finishPlayDate}
                  />
                </Link>
              );
            }) : null}
        </Card>
        {/* <Card backgroundColor='secondary'>
          <CardHeader leftTitle='Cryptocurrency Performance' />
          <div className='h-full max-h-full'>
            <div className='w-full h-max relative'>
              <Link
                to='/dashboard/bitcoin'
                className='absolute w-full h-full z-infinity'
              ></Link>
              <TradingViewWidget
                symbol='COINBASE:BTCUSD'
                containerRef={BTCReff}
              />
            </div>
            <div className='w-full h-max relative'>
              <Link
                to='/dashboard/ethereum'
                className='absolute w-full h-full z-infinity'
              ></Link>
              <TradingViewWidget
                symbol='COINBASE:ETHUSD'
                containerRef={ETHReff}
              />
            </div>
            <div className='w-full h-max relative'>
              <Link
                to='/dashboard/polkadot'
                className='absolute w-full h-full z-infinity'
              ></Link>
              <TradingViewWidget
                symbol='COINBASE:DOTUSD'
                containerRef={DOTReff}
              />
            </div>
            <div className='w-full h-max relative'>
              <Link
                to='/dashboard/houbitoken'
                className='absolute w-full h-full z-infinity'
              ></Link>
              <TradingViewWidget symbol='CRYPTO:HTUSD' containerRef={HTReff} />
            </div>
            <div className='w-full h-max relative'>
              <Link
                to='/dashboard/ripple'
                className='absolute w-full h-full z-infinity'
              ></Link>
              <TradingViewWidget
                symbol='COINBASE:XRPUSD'
                containerRef={XRPReff}
              />
            </div>
          </div>
        </Card> */}
      </div>
      <ModalDepositUser open={open} close={() => setOpen(false)} />
    </div>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* {InvestmentList.map((el, i) => {
  return (
    <CryptoPerformance
      label={el.label}
      path={el.path}
      price={'RM32,000.00'}
      icon={el.icon}
      percentage={'0.00335%'}
      key={i}
    />
  );
})} */
}
