import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function RHFInputAddon({ name, label, addon, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="flex flex-col gap-1">
                    {label && (
                        <label className="mb-1 text-sm font-medium text-gray-900">{label}</label>
                    )}
                    <div className="flex">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-100 border border-r-0 border-gray-200 rounded-l-md">
                            {addon}
                        </span>
                        <input
                            {...field}
                            className="rounded-none rounded-r-lg border text-gray-900 focus:ring-blue-500 focus:border-blue-500 flex-1 min-w-0 w-full text-sm border-gray-200 p-2.5"
                            autoComplete="off"
                            {...other}
                        />
                    </div>
                    {error?.message && (
                        <p className="text-red-500 text-xs">{error?.message}</p>
                    )}
                </div>
            )}
        />
    );
}
