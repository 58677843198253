import { useEffect, useState } from "react";

export default function BadgePercentage({ percentage, isProfit, isPlay }) {
  const [isIncreasing, setIsIncreasing] = useState(true);
  const [prevPercentage, setPrevPercentage] = useState(
    percentage.replace("%", "")
  );

  useEffect(() => {
    const currentPercentage = parseFloat(percentage.replace("%", ""));
    if (
      !isNaN(currentPercentage) &&
      currentPercentage !== prevPercentage &&
      isPlay
    ) {
      setIsIncreasing(currentPercentage >= prevPercentage);
      setPrevPercentage(currentPercentage);
    }
  }, [percentage, prevPercentage, isPlay]);

  return (
    <div
      className={`${
        isPlay && isIncreasing
          ? "bg-c-green"
          : isPlay && !isIncreasing
          ? "bg-red-500"
          : !isPlay && isProfit
          ? "bg-c-green"
          : !isPlay && !isProfit
          ? "bg-red-500"
          : null
      } h-max py-1 px-2 rounded-lg flex items-center justify-center space-x-1 duration-100 transition-colors`}
    >
      <p
        className={`capitalize text-sm text-white font-semibold ${
          isPlay ? "" : ""
        } `}
      >
        {isProfit ? percentage : `${percentage}`}
      </p>
    </div>
  );
}
