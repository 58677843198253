import React, { useState, useEffect } from "react";
import AuthGuard from "./auth.guard";
import AdminGuard from "./admin.guard";
import AccessPageGuard from "./access.guard";
import ModalMessage from "../components/dashboard/modal/ModalMessage";
import useMessage from "../hooks/zustand/member/useMessage";
import useAuth from "../hooks/zustand/useAuth";

const WithGuard = (WrappedComponent) => {
  const WithGuardComponent = () => {
    const msg = useMessage();
    const [open, setOpen] = useState(false);

    async function initialize() {
      const res = await msg.getMessage();
      if (res?.isAvailable) {
        setOpen(true);
      }
    }

    useEffect(() => {
      initialize();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useAuth.getState().auth?.id]);

    return (
      <AuthGuard>
        <ModalMessage
          open={open}
          setOpen={async () => {
            setOpen(false);
            await msg.updateTotalClick();
          }}
          title={msg.message?.subject}
          content={msg.message?.content}
        />
        <WrappedComponent />
      </AuthGuard>
    );
  };

  return WithGuardComponent;
};

const AdminWithGuard = (WrappedComponent, keyAccess, bypassAccess) => {
  // console.log(keyAccess);
  return () => (
    <AdminGuard>
      <AccessPageGuard keyAccess={keyAccess} bypassAccess={bypassAccess}>
        <WrappedComponent></WrappedComponent>
      </AccessPageGuard>
    </AdminGuard>
  );
};

export { WithGuard, AdminWithGuard };
