import { Routes as R, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';

TopBarProgress.config({
  barColors: {
    0: '#ff0000',
    0.5: '#ff0000',
    '1.0': '#ff0000',
  },
  shadowBlur: 1,
  barThickness: 2,
});

export default function Routes({ children }) {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <TopBarProgress />}
      <R>{children}</R>
    </>
  );
}
