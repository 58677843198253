// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCjHqJDeg7m2dew79rlglLok8jI7qbZaQM",
    authDomain: "scfx-otp-d7b56.firebaseapp.com",
    projectId: "scfx-otp-d7b56",
    storageBucket: "scfx-otp-d7b56.appspot.com",
    messagingSenderId: "295411285069",
    appId: "1:295411285069:web:f5ce836825153f57c8b004"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);
