import useAdminAuth from '../hooks/zustand/admin/useAdminAuth';

export default function AccessPageGuard({
  keyAccess,
  children,
  bypassAccess = false,
}) {
  const access = useAdminAuth();

  return access.admin?.access?.[keyAccess] || bypassAccess ? (
    children
  ) : (
    <div className='w-full h-[65vh] flex justify-center items-center'>
      <p className='text-white'>
        You dont have permission to access this page!
      </p>
    </div>
  );
}
