export const mockSliders = [
  {
    bgImg: '/images/about-us/about-us.png',
    title: 'Dream Big. Achieve Bigger',
    desc: 'Trade with a Global Broker to achieve your investment goals.',
    srcImg: '/images/BitCoin.png',
    altImg: 'Bit Coin',
  },
  {
    bgImg: '/images/about-us/about-us.png',
    title: 'Invest Better Together',
    desc: 'Get investment ideas from trusted broker & friendly platform.',
    srcImg: '/images/Investment.png',
    altImg: 'Invest',
  },
  {
    bgImg: '/images/KLCC-scaled.jpg',
    title: 'Trade Crypto',
    desc: 'With Malaysia Ringgit and 24/7 support.',
    srcImg: '',
    altImg: '',
  },
  {
    bgImg: '/images/Lisenced-Broker-scaled.jpg',
    title: 'Trade with a llisenced Broker',
    desc: 'Committed to Data Security & Quality Service.',
    srcImg: '',
    altImg: '',
  },
];

export const mockTrushted = [
  {
    title: 'Reliable',
    desc: 'Regulated by various authorities',
  },
  {
    title: 'Secured',
    desc: 'Utilising top-tier security practices',
  },
  {
    title: 'Social',
    desc: 'More than 10 million users',
  },
];

export const mockStarted = [
  {
    title: 'Sign up',
    desc: 'Create an account for free using your email address',
  },
  {
    title: 'Explore the platform',
    desc: "See what it's like to invest.",
  },
  {
    title: 'Invest & earn profit',
    desc: 'Select an asset and make your money work for you',
  },
];

export const mockInvest = [
  {
    crypto: 'Bitcoin',
    currency: 'RM140.12K',
    percent: '-1.76%',
    icon: '/svg/bitcoin-logo.svg',
    chart: '/svg/chart-btc.svg',
  },
  {
    crypto: 'Ethereum',
    currency: 'RM8.61K',
    percent: '-1.61%',
    icon: '/svg/ethereum-logo.svg',
    chart: '/svg/chart-eth.svg',
  },
  {
    crypto: 'Polkadot',
    currency: 'RM23.57',
    percent: '-2.93%',
    icon: '/svg/polkadot-logo.svg',
    chart: '/svg/chart-pkd.svg',
  },
  {
    crypto: 'Huobi',
    currency: 'RM12.59',
    percent: '-0.88%',
    icon: '/svg/huobi-logo.svg',
    chart: '/svg/chart-huobi.svg',
  },
  {
    crypto: 'XRP',
    currency: 'RM2.19',
    percent: '-1.96%',
    icon: '/svg/xrp-logo.svg',
    chart: '/svg/chart-xrp.svg',
  },
];

export const mockTestimoni = [
  {
    name: 'Adam bin Kamal',
    testimoni: `${ process.env.REACT_APP_PROJECT_TITLE } is the best broker I recommend`,
    desc: 'Deposit and withdrawal are instant and the spread is good',
  },
  {
    name: 'Amirah binti Amir',
    testimoni: 'Easy and smooth using to make a profit…',
    desc: 'Easy and smooth using to make a profit that can change our life so.',
  },
  {
    name: 'Azhar bin Azman',
    testimoni: 'Very good broker.',
    desc: 'Very good broker with 100% bonus new deposit and fast for withdraw',
  },
  {
    name: 'Azlina binti Azmi',
    testimoni: 'Fast deposit & withdrawal.',
    desc: 'Fast deposit & withdrawal.',
  },
  {
    name: 'Badrul Hisham bin Badrul Amin',
    testimoni: 'Fast transaction.',
    desc: 'Fast transaction, Professional customer service and support, I highly recommend them.',
  },
  {
    name: 'Danial Irfan bin Danial',
    testimoni: 'Excellent system no problem in withdrawal…',
    desc: 'Super fast withdrawal.',
  },
  {
    name: 'Ehsan Hakim bin Ehsan',
    testimoni: 'The best among the among the equal.',
    desc: 'Quick payput & best plan to invest in.',
  },
  {
    name: 'Farhan Shah bin Farhan',
    testimoni: 'Best plan To Earn Money Easy.',
    desc: "Broker are professional & friendly. Even, i'm old, they are willing to guide me thru steps by steps.",
  },
  {
    name: 'Hafizuddin bin Hafiz',
    testimoni: 'Best Forex Trading Platform in Malaysia.',
    desc: 'Best Forex Trading Platform in Malaysia.',
  },
  {
    name: 'Haziq Iqmal bin Haziq',
    testimoni: 'Good features to handle trades and easy to use',
    desc: `All the Transactions I do these are absolutely very satisfactory, it's been more than a year I am trading with ${process.env.REACT_APP_PROJECT_TITLE}, and I am sure that I will be continuing for good.`,
  },
  {
    name: 'Irfan Hakim bin Irfan',
    testimoni: `${process.env.REACT_APP_PROJECT_TITLE} reviewed by Irfan`,
    desc: 'Quick Widrawals and instant deposits, quick response from live customer support. Excellent broker!',
  },
  {
    name: 'Iskandar Zulkarnain bin Iskandar',
    testimoni: 'Good customer care & broker service',
    desc: 'Good customer care, Good Rebate, accurate signals.',
  },
  {
    name: 'Kamaluddin bin Kamal',
    testimoni: 'Best broker apps that i ever used.',
    desc: 'The best broker app i have ever used, They were giving free webinar, prices.......etc',
  },
  {
    name: 'Khalid bin Khalid',
    testimoni: ` ${process.env.REACT_APP_PROJECT_TITLE} is easy to use & user friendly.`,
    desc: `${process.env.REACT_APP_PROJECT_TITLE} is easy to use & user friendly.`,
  },
  {
    name: 'Malik bin Malik',
    testimoni: `${process.env.REACT_APP_PROJECT_TITLE} is amazing`,
    desc: ` ${process.env.REACT_APP_PROJECT_TITLE} is amazing`,
  },
];
