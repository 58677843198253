export default function convertDate(val) {
  // const date = new Date(val);

  // const day = date.getDate();
  // const month = date.getMonth() + 1;
  // const year = date.getFullYear();
  // const hours = date.getHours();
  // const minutes = date.getMinutes();

  // const formattedDate = `${day}/${(month < 10 ? '0' : '') + month
  //   }/${year} ${hours}:${(minutes < 10 ? '0' : '') + minutes}`;

  const formattedDate = new Date(val)
    .toLocaleString('id-ID', {
      timeZone: 'Asia/Kuala_Lumpur',
      second: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\./g, ':').replace(/\,/g, '');

  return formattedDate;
}

export function convertTime(val) {
  // const date = new Date(val);

  // const day = date.getDate();
  // const month = date.getMonth() + 1;
  // const year = date.getFullYear();
  // const hours = date.getHours();
  // const minutes = date.getMinutes();

  // const formattedDate = `${day}/${(month < 10 ? '0' : '') + month
  //   }/${year} ${hours}:${(minutes < 10 ? '0' : '') + minutes}`;

  const formattedTime = new Date(val)
    .toLocaleString('id-ID', {
      timeZone: 'Asia/Kuala_Lumpur',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).replace(/\./g, ':');

  return formattedTime;
}
