import { HiOutlineExclamationCircle } from 'react-icons/hi';

export default function BadgeSuspend() {
  return (
    <span className='bg-gray-500 h-max py-1 px-2 rounded-full flex items-center justify-center space-x-1'>
      <span className='capitalize text-xs'>Suspended</span>
      <HiOutlineExclamationCircle />
    </span>
  );
}
