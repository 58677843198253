import React from 'react';
import { cva } from 'class-variance-authority';
import { clsxm } from '../../../lib/utils';

const variants = cva('heading', {
  variants: {
    color: {
      accountTab: ['text-3f4254'],
      white: 'text-white',
    },
    size: {
      h1: ['text-3xl lg:text-4xl', 'py-1', 'px-2'],
      h2: ['text-2xl lg:text-3xl', 'py-1', 'px-2'],
      h3: ['text-xl lg:text-2xl', 'py-1', 'px-2'],
      h4: ['text-lg lg:text-xl', 'py-1', 'px-2'],
    },
  },
  defaultVariants: {
    color: 'accountTab',
    size: 'h1',
  },
});

export default function Heading({ className, size, color, ...props }) {
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h1 className={clsxm(variants({ size, color, className }))} {...props} />
  );
}
