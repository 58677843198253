import { useState, useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TradingViewWidget from "../../pages/Dashboard/Coin/TradingView";
import useAuth from "../../hooks/zustand/useAuth";
import useEmblaCarousel from "embla-carousel-react";
// components
import SectionTitle from "../SectionTitle";
import { useTranslation } from "react-i18next";
// import ButtonLp from '../ButtonLp';
// mock data
// import { mockInvest } from '../../mock/mockData';

const HomeInvest = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: false,
  });

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const isLogin = useAuth();

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);

  const { t } = useTranslation();

  return (
    <div className="container mx-auto max-w-screen-xl px-5 mt-20">
      <SectionTitle>{t("home.section2.title")}</SectionTitle>
      {/* <div ref={emblaRef} className='overflow-hidden'>
        <div className='flex my-10 gap-4'>
          {mockInvest.map((item, i) => (
            <div className='flex flex-col' key={i}>
              <div className='w-56 lg:w-full h-48 lg:h-56 flex flex-col justify-between rounded-lg overflow-hidden shadow-lg p-2'>
                <div className='flex items-start gap-4'>
                  <div className='w-14 h-14'>
                    <img
                      src={item.icon}
                      alt={item.icon}
                      className='w-full h-full'
                    />
                  </div>
                  <div>
                    <div className='font-semibold text-md lg:text-xl'>
                      {item.crypto}
                    </div>
                    <div className='font-semibold text-md lg:text-xl'>
                      {item.currency}
                    </div>
                  </div>
                </div>
                <div className='flex items-start gap-4'>
                  <div className='w-1/2'>
                    <img
                      src={item.chart}
                      alt={item.chart}
                      className='w-full h-full'
                    />
                  </div>
                  <div className='w-1/2'>
                    <div className='font-semibold text-md lg:text-lg text-red-500'>
                      {item.percent}
                    </div>
                    <div className='text-xs lg:text-sm text-gray-500 opacity-75'>
                      1D change
                    </div>
                  </div>
                </div>
                <Link to='/sign-up'>
                  <ButtonLp className='capitalize w-full overflow-hidden'>
                    Sign Up
                  </ButtonLp>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex my-10 gap-4">
          <div className="flex flex-col">
            <div className="w-56 lg:w-full h-max rounded-lg overflow-hidden shadow-lg p-2">
              <Link
                to={
                  isLogin.auth?.accessToken &&
                  isLogin.auth?.role?.includes("user")
                    ? "/dashboard/bitcoin"
                    : isLogin.auth?.accessToken &&
                      !isLogin.auth?.role?.includes("user")
                    ? "#"
                    : "sign-in"
                }
                className="absolute w-full h-full z-infinity"
              ></Link>
              <TradingViewWidget
                symbol="COINBASE:BTCUSD"
                containerRef={BTCReff}
                type="mini-chart"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-56 lg:w-full h-max rounded-lg overflow-hidden shadow-lg p-2">
              <Link
                to={
                  isLogin.auth?.accessToken &&
                  isLogin.auth?.role?.includes("user")
                    ? "/dashboard/ethereum"
                    : isLogin.auth?.accessToken &&
                      !isLogin.auth?.role?.includes("user")
                    ? "#"
                    : "sign-in"
                }
                className="absolute w-full h-full z-infinity"
              ></Link>
              <TradingViewWidget
                symbol="COINBASE:ETHUSD"
                containerRef={ETHReff}
                type="mini-chart"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-56 lg:w-full h-max rounded-lg overflow-hidden shadow-lg p-2">
              <Link
                to={
                  isLogin.auth?.accessToken &&
                  isLogin.auth?.role?.includes("user")
                    ? "/dashboard/polkadot"
                    : isLogin.auth?.accessToken &&
                      !isLogin.auth?.role?.includes("user")
                    ? "#"
                    : "sign-in"
                }
                className="absolute w-full h-full z-infinity"
              ></Link>
              <TradingViewWidget
                symbol="COINBASE:DOTUSD"
                containerRef={DOTReff}
                type="mini-chart"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-56 lg:w-full h-max rounded-lg overflow-hidden shadow-lg p-2">
              <Link
                to={
                  isLogin.auth?.accessToken &&
                  isLogin.auth?.role?.includes("user")
                    ? "/dashboard/houbitoken"
                    : isLogin.auth?.accessToken &&
                      !isLogin.auth?.role?.includes("user")
                    ? "#"
                    : "sign-in"
                }
                className="absolute w-full h-full z-infinity"
              ></Link>
              <TradingViewWidget
                symbol="CRYPTO:HTUSD"
                containerRef={HTReff}
                type="mini-chart"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-56 lg:w-full h-max rounded-lg overflow-hidden shadow-lg p-2">
              <Link
                to={
                  isLogin.auth?.accessToken &&
                  isLogin.auth?.role?.includes("user")
                    ? "/dashboard/ripple"
                    : isLogin.auth?.accessToken &&
                      !isLogin.auth?.role?.includes("user")
                    ? "#"
                    : "sign-in"
                }
                className="absolute w-full h-full z-infinity"
              ></Link>
              <TradingViewWidget
                symbol="COINBASE:XRPUSD"
                containerRef={XRPReff}
                type="mini-chart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInvest;
