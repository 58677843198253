import axios from 'axios';
import Cookies from 'js-cookie';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  // baseURL: 'http://localhost:1234/api',
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
});

Api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('accesstoken');
    if (token) {
      config.headers['Authorization'] = JSON.parse(token);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Api.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response?.data?.message === 'Invalid or expired token.') {
      Cookies.remove('accesstoken');
      Cookies.remove('id');
    }
    return err;
  }
);
