import { useState } from 'react';

// Custom hook untuk mengelola data di local storage
function useLocalStorage(key, initialValue) {
  // Mengecek apakah local storage tersedia di browser
  const isLocalStorageAvailable =
    typeof window !== 'undefined' && window.localStorage;

  // Mengambil nilai dari local storage jika ada
  const storedValue = isLocalStorageAvailable
    ? localStorage.getItem(key)
    : null;

  // Menginisialisasi state dengan nilai dari local storage atau initialValue jika tidak ada
  const [value, setValue] = useState(storedValue || initialValue);

  // Fungsi untuk mengubah nilai di local storage dan state
  const setStoredValue = (newValue) => {
    if (isLocalStorageAvailable) {
      localStorage.setItem(key, newValue);
    }
    setValue(newValue);
  };

  return [value, setStoredValue];
}

export default useLocalStorage;
