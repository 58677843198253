import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function RHFCheckBox({ children, name, label, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="flex flex-col gap-1">
                    <div className="flex items-center">
                        <input
                            {...field}
                            type="checkbox"
                            className="w-4 h-4 accent-blue-500 rounded"
                            {...other}
                        />
                        {label ? (
                            <label className="ml-2 text-sm font-medium text-gray-900">{label}</label>
                        ) : (
                            children
                        )}
                    </div>
                    {error?.message && (
                        <p className="text-red-500 text-xs">{error?.message}</p>
                    )}
                </div>
            )}
        />
    );
}
