import { Route } from "react-router-dom";
import Routes from "./components/TopLoadingBar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import RiskDisclosure from "./pages/RiskDisclosure";
import ReturnPolicy from "./pages/ReturnPolicy";
import AMLPolicy from "./pages/AmlPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SignIn from "./pages/Authenticate/SignIn";
import SignUp from "./pages/Authenticate/SignUp";
import ForgotPassword from "./pages/Authenticate/ForgotPassword";
import NotAuthGuard from "./middleware/not.auth.guard";
import DashboardLayout from "./components/dashboard/layout/Dashboard";
import DashboardAdminLayout from "./components/dashboard/layout/Dashboard-admin";
import UserRoutes from "./routes/user.route";
import AdminRoutes from "./routes/admin.route";
import SignInAdmin from "./pages/Authenticate/Admin/Signin";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/risk-disclosure" element={<RiskDisclosure />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/aml-policy" element={<AMLPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        exact
        path="/sign-in"
        element={
          <NotAuthGuard>
            <SignIn />
          </NotAuthGuard>
        }
      />
      <Route path="admin/sign-in" element={<SignInAdmin />} />
      <Route
        exact
        path="/sign-up"
        element={
          <NotAuthGuard>
            <SignUp />
          </NotAuthGuard>
        }
      />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      {/* USER */}
      <Route path="/dashboard" element={<DashboardLayout />}>
        {UserRoutes}
      </Route>
      {/* ADMIN */}
      <Route path="/admin/dashboard/" element={<DashboardAdminLayout />}>
        {AdminRoutes}
      </Route>
    </Routes>
  );
}

export default App;
