import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonLp from "./ButtonLp";
import useAuth from "../hooks/zustand/useAuth";
import i18next from "i18next";

const Header = ({ changeColorWhileScroll = false }) => {
  const isLogin = useAuth();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 80;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  function getInitialColor() {
    let initialColor;
    if (changeColorWhileScroll) {
      initialColor = "bg-blue-scfx";
    } else {
      initialColor = "bg-white";
    }
    if (changeColorWhileScroll && scrolled) {
      initialColor = "bg-white";
    }
    return initialColor;
  }

  function getInitialImage() {
    let initialImage;
    if (changeColorWhileScroll) {
       initialImage = process.env.REACT_APP_LOGO_BG_PATH;
      //initialImage = "/images/Produk-Kewangan-Main-Logo.png";
    } else {
       initialImage = process.env.REACT_APP_LOGO_PATH;
      //initialImage = "/images/Produk-Kewangan-Black-Logo.png";
    }
    if (changeColorWhileScroll && scrolled) {
       initialImage = process.env.REACT_APP_LOGO_PATH;
    }
    return initialImage;
  }

  function changeLanguage(lng) {
    i18next.changeLanguage(lng, (err) => {
      console.warn("An error occured while changin language");
    });
  }
  const navigate = useNavigate();

  const handleLoginChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === 'investor') {
      // Redirect to "/sign-in"
      navigate('/sign-in');
    }
    else{
      navigate('/admin/sign-in');
    }
  };
  return (
    <nav
      className={`h-20 z-50 ${getInitialColor()} sticky top-0 flex items-center duration-300`}
    >
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl w-full p-4">
        {/* <Link to="/">
          <img
            src={`${getInitialImage()}`}
            alt={`Logo ${ process.env.REACT_APP_PROJECT_TITLE }`}
            className="w-20 object-contain object-center"
          />
        </Link> */}
        <div className=" flex flex-auto justify-end">
          {/* <select
            defaultValue="en"
            className="border-none text-sm"
            onChange={(e) => changeLanguage(e.target.value)}
          >
            <option value="en">EN</option>
            <option value="my">MY</option>
            <option value="cn">CN</option>
          </select> */}
        
          {isLogin.auth?.accessToken &&
          isLogin.auth?.role?.replace(/["']/g, "") === "user" ? (
            <Link to="/dashboard">
              <ButtonLp className="uppercase" variant="primary">
                Dashboard
              </ButtonLp>
            </Link>
          ) : isLogin.auth?.accessToken &&
            isLogin.auth?.role?.replace(/["']/g, "") !== "user" &&
            isLogin.auth?.role !== null ? (
            <Link to="/admin/dashboard/user-list">
              <ButtonLp className="uppercase" variant="primary">
                Dashboard
              </ButtonLp>
            </Link>
          ) : !isLogin.auth?.role && !isLogin.auth?.accessToken ? (
              //   <select 
              //   className="border-none uppercase rounded-md bg-blue-500 text-sm font-medium text-white h-10" 
              //   onChange={handleLoginChange}
              // >
              //   <option disabled="true" selected >Sign In</option>
              //   <option value="investor">Investor</option>
              //   <option value="broker">Broker</option>
              // </select>
            <Link to="/sign-in">
              <ButtonLp className="uppercase" variant="primary">
                Sign In
              </ButtonLp>
            </Link>

          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Header;
