import React, { useEffect, useMemo } from 'react';
import Layout from '../components/Layout';
import CountUp from 'react-countup';
import usePageContent from '../hooks/zustand/usePageContent';
import { aboutUs } from '../lib/api/cms-route.constant';
import { shallow } from 'zustand/shallow';

const AboutUs = () => {
  const pageContent = usePageContent(
    (state) => ({ content: state.content, getData: state.getData }),
    shallow
  );

  const about = useMemo(() => {
    return pageContent.content[aboutUs];
  }, [pageContent]);

  useEffect(() => {
    async function getData() {
      await pageContent.getData(aboutUs);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout changeColorWhileScroll={true}>
      <div className='w-full about-us min-h-screen h-full py-28'>
        <div className='relative w-full h-full z-10 max-w-7xl px-5 mx-auto'>
          <div
            id='heading-hero'
            className='flex flex-col items-center justify-center'
          >
            <h1 className='text-4xl lg:text-7xl font-bold text-center text-white'>
              {about?.largeSentence}
            </h1>
            <Spacing />
            <h3 className='text-white text-xl lg:text-3xl text-center'>
              {about?.smallSentence}
            </h3>
            <Spacing />
            <div className='flex flex-col w-full space-y-5'>
              <h3 className='text-white text-4xl max-w-4xl text-start font-bold'>
                Our Mission
              </h3>
              <h3 className='text-white text-xl lg:text-3xl w-full lg:w-2/5'>
                {about?.missionSubHeading}
              </h3>
              <p className='text-lg text-white w-full lg:w-2/5'>
                {about?.missionSubtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full h-full py-28'>
        <div className='relative w-full h-full z-10 px-5 max-w-7xl mx-auto'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
            <div className='space-y-5'>
              <h3 className='text-black font-semibold text-xl lg:text-3xl w-full'>
                {about?.statisticTitle}
              </h3>
              <p className='text-lg text-black w-full'>
                {about?.statisticSubtitle}
              </p>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
              <CounterUp
                number={about?.numberAccountOpened ?? 100}
                variation={about?.numberVariation}
                message={about?.numberSubtitle}
              />
              <CounterUp number={7} message='Cryto trading accounts opened' />
              <CounterUp
                number={about?.numberCountryCovered ?? 100}
                variation={about?.numberCountryVariation}
                message={about?.numberCountrySubtitle}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

function Spacing() {
  return <div className='pt-14 pb-5'></div>;
}

function CounterUp({ number, variation, message }) {
  return (
    <div className='h-36 w-full flex justify-center items-center flex-col'>
      <h1 className='text-7xl text-center text-green-500'>
        <CountUp duration={8} end={number} enableScrollSpy={true} />
        {variation}
      </h1>
      <h3 className='text-lg text-center text-black w-full lg:w-3/5'>
        {message}
      </h3>
    </div>
  );
}
