import { Link } from 'react-router-dom';
import { WaveTop } from '../components/Wave';

const currYear = new Date().getFullYear();
const projectTitle = process.env.REACT_APP_PROJECT_TITLE ?? 'SC FX';

const Footer = () => {
  return (
    <footer>
      <div className='relative'>
        <WaveTop />
      </div>
      {/* <div className='bg-[#0e0e42] pt-10'> */}
      <div className='bg-blue-scfx pt-10'>
        <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
          <div className='flex flex-col-reverse lg:flex-row items-center justify-between gap-4'>
            <Link to='/' className='flex items-center mb-4 sm:mb-0'>
              <img
                src={process.env.REACT_APP_LOGO_BG_PATH}
                alt={`Logo ${ process.env.REACT_APP_PROJECT_TITLE }`}
                className='w-20 object-contain object-center mr-5'
              />
              <span className='self-center text-sm font-semibold whitespace-nowrap text-gray-400'>
               { projectTitle } © { currYear}
              </span>
            </Link>
            <ul className='flex items-center mb-6 text-sm font-semibold text-gray-500 sm:mb-0 dark:text-gray-400'>
              <li>
                <Link to='/about-us' className='mr-4 hover:text-white md:mr-6'>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to='/risk-disclosure'
                  className='mr-4 hover:text-white md:mr-6'
                >
                  Risk Disclosure
                </Link>
              </li>
              <li>
                <Link
                  to='/return-policy'
                  className='mr-4 hover:text-white md:mr-6 '
                >
                  Return Policy
                </Link>
              </li>
              <li>
                <Link to='/aml-policy' className='mr-4 hover:text-white'>
                  AML Policy
                </Link>
              </li>
              <li>
                <Link to='/privacy-policy' className='hover:text-white'>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
