import {
  HiOutlineUserGroup,
  HiOutlinePlusSm,
  // HiOutlineHome,
  HiOutlineFolderOpen,
  HiCreditCard
} from "react-icons/hi";
import { HiOutlineWallet } from "react-icons/hi2";
import useAdminAuth from "../../../../hooks/zustand/admin/useAdminAuth";
// import { AiOutlineSetting } from 'react-icons/ai';
import { useMemo } from "react";

export const MenuAdmin = () => {
  const info = useAdminAuth();

  const x = info.admin?.access;

  const listMenu = [
    // {
    //   key: 'dashboard',
    //   menu: 'Home',
    //   label: 'Dashboard',
    //   icon: null,
    //   subMenuItems: [],
    //   menuItems: [
    //     {
    //       key: 'dashboard',
    //       label: 'Dashboard',
    //       icon: <HiOutlineHome />,
    //       path: '/dashboard/admin',
    //       ac: 'dashboard',
    //     },
    //   ],
    // },
    {
      key: "member",
      menu: "Account",
      label: "Member",
      icon: <HiOutlineUserGroup />,
      subMenuItems: [
        {
          key: "member-listing",
          label: "Member Listing / Create New Member",
          path: "/dashboard/user-list",
          ac: "MemberListing",
        },
        // {
        //   key: 'member-permission',
        //   label: 'Member Permission Control',
        //   path: '/dashboard/admin/member-permission',
        // },
       
        // {
        //   key: 'investment-listing',
        //   label: 'Investment Listing',
        //   path: '/dashboard/admin/investment-listing',
        // },
      ],
    },
    {
      key:"staking",
      menu: "Staking",
      label: "Staking",
      icon: <HiCreditCard />,
      subMenuItems:[
        {
          key: "member-listing",
          label: "Staking Listing",
          path: "/dashboard/staking-list",
          ac: "stakeListing",
        },
      ]
    },
    {
      key: "wallet",
      menu: "Finance",
      label: "Wallet",
      icon: <HiOutlineWallet />,
      subMenuItems: [
        {
          key: "withdrawal-approval",
          label: "Withdrawal Approval",
          path: "/dashboard/withdrawal-approval",
          ac: "withdrawalApproval",
        },
        // {
        //   key: 'deposit-history',
        //   label: 'Deposit Wallet History',
        //   path: '/dashboard/admin/deposit-wallet-history',
        // },
        {
          key: 'profit-loss-wallet-history',
          label: 'Profit History',
          path: '/dashboard/interest-list',
        },
        {
          key: 'interest-member-history',
          label: 'Interest Member History',
          path: '/dashboard/interest-member-list',
        },
        {
          key: "deposit-wallet-adjustment",
          label: "Deposit History",
          path: "/dashboard/deposit-history",
          ac: "depositListing",
        },
        {
          key: "wallet-summary-listing",
          label: "Wallet Summary",
          path: "/dashboard/wallet-summary-listing",
          ac: "walletSummary",
        },
      ],
    },
    // {
    //   key: "com",
    //   menu: "Communication",
    //   label: "Communication",
    //   icon: <HiOutlineWallet />,
    //   subMenuItems: [
    //     {
    //       key: "push-messages",
    //       label: "Push Message",
    //       path: "/dashboard/admin/push-messages",
    //       ac: "messages",
    //     },
    //   ],
    // },
    {
      key: "misc",
      menu: "Admin Management",
      label: "Misc",
      icon: <HiOutlineFolderOpen />,
      subMenuItems: [
        {
          key: "admin-listing",
          label: "Admin List / Create New Admin",
          path: "/dashboard/admin-list",
          ac: "adminListing",
        },
        // {
        //   key: "handler-listing",
        //   label: "Handler Reassigning",
        //   path: "/dashboard/admin/handler-listing",
        //   ac: "handlerAssign",
        // },
        {
          key: "log-listing",
          label: "Admin Log",
          path: "/dashboard/log-list",
          ac: "adminLog",
        },
        // {
        //   key: "admin-access",
        //   label: "Admin Access",
        //   path: "/dashboard/access",
        //   ac: "adminAccess",
        // },
      ],
    },
    // {
    //   key: "page-content",
    //   menu: "Page Content",
    //   label: "Page Content",
    //   icon: null,
    //   subMenuItems: [],
    //   menuItems: [
    //     {
    //       key: "about-us",
    //       label: "About Us",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/about-us-content",
    //       ac: "aboutUs",
    //     },
    //     {
    //       key: "risk-disclosure",
    //       label: "Risk Disclosure",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/risk-disclosure-content",
    //     },
    //     {
    //       key: "return-policy",
    //       label: "Return Policy",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/return-policy-content",
    //     },
    //     {
    //       key: "aml-policy",
    //       label: "AML Policy",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/aml-policy-content",
    //     },
    //     {
    //       key: "privacy-policy",
    //       label: "Privacy policy",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/privacy-policy-content",
    //     },
    //     {
    //       key: "home-slider",
    //       label: "Home Slider",
    //       icon: <HiOutlinePlusSm />,
    //       path: "/dashboard/admin/home-slider",
    //       ac: "homeSlider"
    //     },
    //   ],
    // },
  ];

  const updatedListMenu = useMemo(() => {
    return listMenu
      .map((menuItem) => {
        if (x?.hasOwnProperty(menuItem.ac)) {
          if (!x[menuItem.ac]) {
            return null;
          }
        }
        if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
          const updatedSubMenuItems = menuItem.subMenuItems
            .map((subMenuItem) => {
              if (x?.hasOwnProperty(subMenuItem.ac)) {
                if (!x[subMenuItem.ac]) {
                  return null;
                }
              }
              return subMenuItem;
            })
            ?.filter(Boolean);

          menuItem.subMenuItems = updatedSubMenuItems;
        }
        if (menuItem.menuItems && menuItem.menuItems.length > 0) {
          const updatedMenuItems = menuItem.menuItems
            .map((menuItem) => {
              if (x?.hasOwnProperty(menuItem.ac)) {
                if (!x[menuItem.ac]) {
                  return null;
                }
              }
              return menuItem;
            })
            ?.filter(Boolean);

          menuItem.menuItems = updatedMenuItems;
        }
        return menuItem;
      })
      ?.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x]);

  return updatedListMenu;
};
