import { create } from "zustand";
import { Api } from "../../../lib/api/axios";
import useAuth from "../useAuth";

const useMessage = create((set, get) => ({
  message: {},
  getMessage: async () => {
    try {
      const res = await Api.get(
        `/cms/push-message/member/${useAuth.getState().auth?.id}`
      );
      set(() => ({ message: res.data }));
      return res.data;
    } catch (error) {
      return error;
    }
  },
  updateTotalClick: async () => {
    try {
      return await Api.patch(
        `/cms/push-message/member-click/${useAuth.getState().auth?.id}/${
          get().message?._id
        }`
      );
    } catch (error) {
      return error;
    }
  },
}));

export default useMessage;
